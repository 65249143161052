<template>
<div class="main-wrapper">
  <Header/>
  <Banner name="404 Error" message="Page Not Found" />
   <!-- Error Start -->
    <div class="section section-padding mt-n10">
        <div class="container">

            <!-- Error Wrapper Start -->
            <div class="error-wrapper">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <!-- Error Images Start -->
                        <div class="error-images">
                            <img src="@/assets/images/error.png" alt="Error">
                        </div>
                        <!-- Error Images End -->
                    </div>
                    <div class="col-lg-6">
                        <!-- Error Content Start -->
                        <div class="error-content">
                            <h5 class="sub-title">This Page is Not Found.</h5>
                            <h2 class="main-title">We are very sorry for error. We <span> can’t find this</span> page.</h2>
                            <p>It has survived not only five centuries but also the leap into electronic typesetting.</p>
                            <a href="index.html" class="btn btn-primary btn-hover-dark">Back To Home</a>
                        </div>
                        <!-- Error Content End -->
                    </div>
                </div>
            </div>
            <!-- Error Wrapper End -->

        </div>
    </div>
    <!-- Error End -->
  <Footer/>
</div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Banner from '@/components/Banner.vue'
export default {
  name: '404',
  components: {
    Header,Footer,Banner
  }
}
</script>